package it.neckar.bioexp.rest.detect

import it.neckar.open.kotlin.serializers.ByteArraySerializer
import it.neckar.open.unit.other.px
import kotlinx.serialization.Serializable

/**
 * represents an image detection result for RBCs
 */
@Serializable
data class RBCDetectionResult(
  val filename: String,
  val modelImageSize: Int, //use value class Size instead#

  /**
   * Height of the original image
   */
  val imageHeight: @px Int,

  /**
   * Width of the original image
   */
  val imageWidth: @px Int,
  val detectedObjects: List<DetectedCell>,
  val boundingBoxImage: @Serializable(with = ByteArraySerializer::class) ByteArray?,
) {
  override fun equals(other: Any?): Boolean {
    if (this === other) return true
    if (other == null || this::class != other::class) return false

    other as RBCDetectionResult

    if (filename != other.filename) return false
    if (modelImageSize != other.modelImageSize) return false
    if (detectedObjects != other.detectedObjects) return false
    if (boundingBoxImage != null) {
      if (other.boundingBoxImage == null) return false
      if (!boundingBoxImage.contentEquals(other.boundingBoxImage)) return false
    } else if (other.boundingBoxImage != null) return false

    return true
  }

  override fun hashCode(): Int {
    var result = filename.hashCode()
    result = 31 * result + modelImageSize
    result = 31 * result + detectedObjects.hashCode()
    result = 31 * result + (boundingBoxImage?.contentHashCode() ?: 0)
    return result
  }
}
