package store.actions

import it.neckar.common.redux.ChangeType
import store.BioExpUiState
import store.LoggedInState

data class LoggedInLoadedFromLocalStorageAction(val loadedLoggedInState: LoggedInState) : StateAction {
  override val type: ChangeType = ChangeType.HistoryReset

  override fun BioExpUiState.updateState(): BioExpUiState {
    return this.copy(loginState = loadedLoggedInState)
  }
}
