package components

import it.neckar.commons.kotlin.js.safeGet
import kotlinx.html.InputType
import kotlinx.html.id
import kotlinx.html.js.onChangeFunction
import kotlinx.html.lang
import org.w3c.dom.events.Event
import react.*
import react.dom.*
import services.UiActions

/**
 * inputfield to select the detection threshold
 */
val ThresholdSelector: FC<ThresholdSelectorProps> = fc("ThresholdSelector") { props ->
  val thresholdOnChange: (Event) -> Unit = useCallback { event ->
    val threshold = event.target.asDynamic().value.unsafeCast<Double>()
    UiActions.setThreshold(threshold)
  }

  div("row") {
    div("mt-3 form-outline col-sm-2") {
      label("form-label") {
        attrs["htmlFor"] = "threshold"
        +"Threshold (0.5-0.95)"
      }
      input(classes = "form-control") {
        attrs {
          id = "threshold"
          type = InputType.number
          lang = "en"
          defaultValue = props::defaultValue.safeGet().toString()
          max = props::max.safeGet().toString()
          min = props::min.safeGet().toString()
          step = props::step.safeGet().toString()
          onChangeFunction = thresholdOnChange
        }
      }
    }
  }
}

external interface ThresholdSelectorProps : Props {
  var defaultValue: Double
  var max: Double
  var min: Double
  var step: Double
}
