package components.table

import it.neckar.commons.kotlin.js.safeGet
import it.neckar.react.common.*
import it.neckar.react.common.button.*
import it.neckar.react.common.table.*
import it.neckar.react.common.toast.*
import react.*
import react.dom.*
import web.navigator.navigator

/**
 * Table that shows the results of the detection
 */
val ResultsTable: FC<ResultsTableProps> = fc("ResultsTable") { props ->
  div {

    actionButton(ButtonIcon.centered(FontAwesomeIcons.copy), action = {
      val tableString = props::tableRows.safeGet().joinToString("\n") { "${it.cellName}\t${it.count}" }
      //prepend header to tableString
      navigator.clipboard.writeText("Class\tDetections\n$tableString")
      notifySuccess("Table copied to clipboard")
    }) {
      +" Copy Table"
    }

    val nameHeaderColumn: TableHeaderColumn<CellTableRow> = TableHeaderColumn("idHeaderColumn", "Class") {
      {
        p {
          +it.cellName
        }
      }
    }

    val detectionsColumn: TableDataColumn<CellTableRow> = TableDataColumn("detectionsColumn", "Detections") {
      {
        p {
          +it.count.toString()
        }
      }
    }

    val initiallySortedBy: SortedByFunction<CellTableRow> = SortedByFunction(
      sortedBy = SortedBy.SortedAscending,
      sortedColumn = nameHeaderColumn,
    )

    div("mt-3 bg-white") {
      table(
        columns = listOf(nameHeaderColumn, detectionsColumn),
        entries = props::tableRows.safeGet(),
        sortedByFunction = useState(initiallySortedBy)
      )
    }
  }
}

external interface ResultsTableProps : Props {
  var tableRows: List<CellTableRow>
}

data class CellTableRow(
  val cellName: String,
  val count: Int,
)
