package it.neckar.bioexp.rest.message

import kotlinx.serialization.Serializable

/**
 *         ------------------------------------------------------------------|
 *         | Parameter               |   Range       |   Default             |
 *         |-----------------------------------------------------------------|
 *         | Auto Exposure Target    |   16~235      |   120                 |
 *         | Exposure Gain           |   100~        |   100                 |
 *         | Temp                    |   2000~15000  |   6503                |
 *         | Tint                    |   200~2500    |   1000                |
 *         | LevelRange              |   0~255       |   Low = 0, High = 255 |
 *         | Contrast                |   -100~100    |   0                   |
 *         | Hue                     |   -180~180    |   0                   |
 *         | Saturation              |   0~255       |   128                 |
 *         | Brightness              |   -64~64      |   0                   |
 *         | Gamma                   |   20~180      |   100                 |
 *         | WBGain                  |   -127~127    |   0                   |
 *         ------------------------------------------------------------------|
 *
 */
@Serializable
data class CameraSettings(
  val autoExposureEnabled: Boolean = false,
  val autoExposureTarget: Int? = 120,
  val exposureGain: Int? = 100,
  val temp: Int? = 6503,
  val tint: Int? = 1000,
  val contrast: Int? = 0,
  val hue: Int? = 0,
  val saturation: Int? = 128,
  val brightness: Int? = 0,
  val gamma: Int? = 100,
)
