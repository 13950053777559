package store

import it.neckar.open.unit.other.pct

/**
 * image detection threshold state
 */
sealed class ThresholdState {
  abstract val thresholdValue: Double
}

/**
 * Sets the image detection threshold
 *
 * @param threshold the threshold value
 */
data class SetThresholdState(val threshold: @pct Double) : ThresholdState() {
  override val thresholdValue: Double
    get() = threshold
}
