package it.neckar.rest

import it.neckar.rest.pagination.PaginationInfo
import kotlinx.serialization.Transient
import kotlin.contracts.contract

/**
 * Represents a REST response
 *
 * @param DataType the type that is returned by the [SuccessResponse].
 * Might be nullable in some cases (e.g., no image available because none has been stored, but everything works)
 *
 * For sorting and filtering, use [RestResponseFilteredSorted]
 */
interface RestResponse<out DataType> {

  /**
   * Returns true if the result is a success
   */
  @Transient
  val isSuccess: Boolean

  /**
   * Returns true if the result is a failure
   */
  @Transient
  val isFailure: Boolean
    get() = isSuccess.not()

  /**
   * Casts this to a success object or throws an exception
   */
  fun asSuccess(): SuccessResponse<DataType>

  /**
   * Represents a success response
   */
  interface SuccessResponse<out DataType> : RestResponse<DataType> {

    /**
     * The data that is provided by a successful response
     */
    val data: DataType
  }

  /**
   * Represents a success response including pagination information
   */
  interface PaginatedSuccessResponse<out DataType> : SuccessResponse<DataType> {
    /**
     * The pagination information.
     * Never returns null, since all endpoints *must* support pagination
     */
    val paginationInfo: PaginationInfo
  }

  /**
   * Tagging interface for failure responses
   */
  interface FailureResponse<out DataType> : RestResponse<DataType>
}


/**
 * Executes the provided block if this is a failure response
 */
fun <T : RestResponse<*>> T.ifFailure(block: (response: RestResponse.FailureResponse<*>?) -> Unit): T {
  contract {
    callsInPlace(block, kotlin.contracts.InvocationKind.AT_MOST_ONCE)
  }

  if (isFailure) {
    block(this as RestResponse.FailureResponse<*>)
  }

  return this
}
