import com.meistercharts.js.MeisterChartsPlatform
import components.BioExpRoot
import it.neckar.bioexp.rest.client.DeploymentZone
import it.neckar.common.featureflags.FeatureFlag
import it.neckar.common.featureflags.FeatureFlags
import it.neckar.common.featureflags.FeatureFlagsSupport
import it.neckar.common.featureflags.loadFeatureFlagsFromUrlOrLocalStorage
import it.neckar.common.featureflags.writeToLocalStorage
import it.neckar.common.featureflags.writeToUrl
import it.neckar.commons.kotlin.js.exception.ConsoleJsErrorHandler
import it.neckar.commons.kotlin.js.exception.JsErrorHandler
import it.neckar.commons.kotlin.js.exception.JsErrorHandlerMultiplexer
import it.neckar.logging.Level
import it.neckar.logging.LogConfigurer
import it.neckar.logging.Logger
import it.neckar.logging.LoggerFactory
import it.neckar.open.version.VersionInformation
import kotlinx.browser.window
import react.*
import react.dom.client.*
import services.UiServices
import store.StoreSupport
import web.html.HTMLElement

private val logger: Logger = LoggerFactory.getLogger("bioexp-ui")

fun main() {
  println("Cell Detection Starting")

  //Register the error handler
  JsErrorHandler.registerWindowErrorHandler(JsErrorHandlerMultiplexer(
    listOf(
    ConsoleJsErrorHandler,

      //We do *not* want to show unknown exception as toasts
      //Instead we show them using an error page
    object : JsErrorHandler {
      override fun error(throwable: Throwable) {
        // store.dispatch(ErrorOccurredAction(throwable))
      }

      override fun otherError(message: dynamic, error: Any) {
        //store.dispatch(ErrorOccurredAction(RuntimeException(message.toString())))
      }

      override fun nullError(message: dynamic) {
        TODO("Not yet implemented")
      }
    }
  ))
  )


  LogConfigurer.initializeFromLocalStorage(Level.DEBUG)

  logger.info("Starting UI: ${VersionInformation.versionAsStringVerbose}")

  val deploymentZone = DeploymentZone.Client.getClientDeploymentZoneFromWindowLocation(window.location.href)

  MeisterChartsPlatform.init()


  UiServices.initialize(deploymentZone)

  StoreSupport.initializeStore(deploymentZone)

  initializeFeatureFlags(deploymentZone)

  //Start the React app
  val rootDiv: HTMLElement = web.dom.document.getElementById("root") ?: error("Element root not found")
  createRoot(rootDiv).render(createElement<Props> {
    BioExpRoot {}
  })

}


private fun initializeFeatureFlags(deploymentZone: DeploymentZone.Client) {
  val loadedFeatureFlags: FeatureFlags = loadFeatureFlagsFromUrlOrLocalStorage() ?: FeatureFlags.empty
  logger.debug("loadedFeatureFlags: $loadedFeatureFlags")

  val featureFlags: FeatureFlags = if (deploymentZone.isLocalhost() && loadedFeatureFlags.flags.isEmpty()) {
    logger.debug("Adding ${FeatureFlag.disableVersionCheck}")

    // Add feature flag "disable version check" on localhost
    loadedFeatureFlags + FeatureFlag.disableVersionCheck
  } else {
    //Assign directly
    loadedFeatureFlags
  }

  //Set the feature flags to the global support
  FeatureFlagsSupport.flags = featureFlags
  FeatureFlagsSupport.flags.writeToUrl() //Update the URL (might be redundant)
  FeatureFlagsSupport.flags.writeToLocalStorage() //Update local storage (might be redundant)
}
