package it.neckar.projects.bioexp

/**
 * The supported hostnames for BioExP
 */
enum class BioExPHost(
  val hostname: String,
  val role: Role,
  val environment: Environment,
) {
  /**
   * Used to run the cloud services on localhost
   */
  LocalhostCloud("localhost-cloud", Role.Cloud, Environment.Development),

  /**
   * Used to run the on premise services on localhost
   */
  LocalhostLabStation("localhost-lab-station", Role.OnPremise, Environment.Development),

  /**
   * The PC that is delivered and placed in the lab
   */
  LabStation("lab-station", Role.OnPremise, Environment.Development),

  Playground("playground.bioexp.neckar.it", Role.Cloud, Environment.Production),
  //PlaygroundAI("playground.bioexp.neckar.ai"),

  CellCount("cellcount.neckar.it", Role.Cloud, Environment.Production),
  //CellCountAI("cellcount.neckar.ai"),

  Production("bioexp.neckar.it", Role.Cloud, Environment.Production),
  //ProductionAI("bioexp.neckar.ai"),
  ;

  enum class Role {

    /**
     * In the cloud, no camera and Opc/Ua
     */
    Cloud,

    /**
     * On premise, including camera and Opc/Ua
     */
    OnPremise,
  }

  /**
   * The environment where the service has been started in
   */
  enum class Environment {
    /**
     * Production - use the production database
     */
    Production,

    /**
     * Staging area
     */
    Staging,

    /**
     * (Local) development (usually during development on localhost)
     */
    Development,
    ;

    fun isDevelopment(): Boolean {
      return this == Development
    }
  }
}
