package components.cellcount

import components.ThresholdSelector
import it.neckar.react.common.*
import it.neckar.react.common.button.*
import it.neckar.react.common.upload.*
import kotlinx.browser.window
import kotlinx.coroutines.*
import kotlinx.css.*
import org.w3c.fetch.CORS
import org.w3c.fetch.RequestInit
import org.w3c.fetch.RequestMode
import org.w3c.fetch.Response
import org.w3c.files.Blob
import react.*
import react.dom.*
import services.UiActions
import web.file.File

/**
 * separate page for Cellcount image selection
 */
val CellcountImageSelectionPage: FC<Props> = fc("CellcountImageSelectionPage") { props ->

  suspend fun convertImageToFile(imgSrc: String, fileName: String): File {
    UiActions.setImageSelectedForUploadSOP2()
    // Fetch the image and convert to blob
    val response: Response = window.fetch(imgSrc, RequestInit(mode = RequestMode.CORS)).await()
    val blob: Blob = response.blob().await()

    // Create a File object with the blob
    return File(arrayOf(blob), fileName)
  }

  div("ps-0 container-fluid text-white") {

    div("container pt-5 py-3") {

      h1("text-center fw-bold") {
        +"RBC Shape Detection"
      }

      div("row") {
        div("mt-3") {
          ImageUploadField{
            attrs{
              fieldText = "Select or Drop Image (.jpg, .jpeg, .png)"
              uploadFunction = { file -> UiActions.loadFileSOP2(file) }
              height = LinearDimension("20rem")
              acceptableFileTypes = listOf(".jpg", ".jpeg", ".png")
            }
          }
        }
      }

      ThresholdSelector {
        attrs {
          min = 0.5
          max = 0.95
          step = 0.01
          defaultValue = 0.75
        }
      }

      div("mt-5 fs-4") {
        h2("fw-bold") {
          +"Instructions"
        }
        ol {
          li {
            +"Drop or select the image to run RBC detection on"
          }
          li {
            +"The image will be uploaded and cell types will be detected automatically"
          }
          li {
            +"Results will be generated and presented"
          }
        }

      }


        div("mt-5 fs-4") {
          h2("fw-bold") {
            +"Disclaimer"
          }
          +"The accuracy of our object detection model heavily relies on the dataset it was trained on. At this stage, the model may not accurately detect objects in all images due to limitations in the diversity and comprehensiveness of the training data."
        }

        h2("fw-bold") {
          +"Examples"
        }

        val imagePathBeforeExample1 = "img/cells/before-after/example-1-before.jpg"
        val imagePathBeforeExample2 = "img/cells/before-after/example-2-before.jpg"
        val imagePathBeforeExample3 = "img/cells/before-after/example-3-before.jpg"
        val imagePathAfterExample1 = "img/cells/before-after/example-1-after.jpeg"
        val imagePathAfterExample2 = "img/cells/before-after/example-2-after.jpeg"
        val imagePathAfterExample3 = "img/cells/before-after/example-3-after.jpeg"

        div("row mt-3") {

          div("col-sm-6 d-flex") {
            img(src = imagePathBeforeExample1, classes = "flex-fill mw-100") {}
          }

          div("col-sm-6 d-flex mt-2") {
            img(src = imagePathAfterExample1, classes = "flex-fill mw-100") {}
          }
        }

        hr("border border-top border-3") { }

        div("row mt-3") {

          div("col-sm-5 d-flex") {
            img(src = imagePathBeforeExample2, classes = "flex-fill mw-100") {}
          }

          div("col-sm-5 d-flex mt-2") {
            img(src = imagePathAfterExample2, classes = "flex-fill mw-100") {}
          }

          div("col-sm-2 d-flex justify-content-start align-items-center") {
            actionButton(ButtonIcon.centered(FontAwesomeIcons.magnifyingGlass), "btn btn-primary mt-3", action = suspend {
              val file = convertImageToFile(
                imagePathBeforeExample2, "Example-2.jpg"
              )
              UiActions.loadFileSOP2(file)
            }) {
              +" Test detection on this image"
            }
          }
        }

        hr("border border-top border-3") { }

        div("row mt-3") {

          div("col-sm-5 d-flex") {
            img(src = imagePathBeforeExample3, classes = "flex-fill mw-100") {}
          }

          div("col-sm-5 d-flex mt-2") {
            img(src = imagePathAfterExample3, classes = "flex-fill mw-100") {}
          }

          div("col-sm-2 d-flex justify-content-start align-items-center") {
            actionButton(ButtonIcon.centered(FontAwesomeIcons.magnifyingGlass), "btn btn-primary mt-3", action = suspend {
              val file = convertImageToFile(
                imagePathBeforeExample3, "Example-3.jpg"
              )
              UiActions.loadFileSOP2(file)
            }) {
              +" Test detection on this image"
            }
          }
        }
      }
  }
}
