package it.neckar.bioexp.rest.client

import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.http.*
import it.neckar.bioexp.rest.message.CameraImageResponse
import it.neckar.bioexp.rest.message.CameraSettings
import it.neckar.bioexp.rest.message.CameraSettingsResponse
import it.neckar.ktor.client.featureFlagsHeader
import it.neckar.ktor.client.get
import it.neckar.ktor.client.put
import it.neckar.ktor.client.safeTryFetch

class CameraClient(val httpClient: HttpClient, val urlSupport: UrlSupport) {
  suspend fun captureImage(additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {}): CameraImageResponse {
    return safeTryFetch(CameraImageResponse.Failure) {
      httpClient
        .put(urlSupport.camera.captureImage) {
          featureFlagsHeader()
          additionalRequestConfiguration()
        }.body()
    }
  }

  suspend fun setSettings(settingsRequest: CameraSettings, additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {}): CameraSettingsResponse{
    return safeTryFetch(CameraSettingsResponse.Failure) {
      httpClient
        .put(urlSupport.camera.settings) {
          featureFlagsHeader()
          setBody(settingsRequest)
          contentType(ContentType.Application.Json)
          additionalRequestConfiguration()
        }.body()
    }
  }

  suspend fun getSettings(additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {}): CameraSettingsResponse {
    return safeTryFetch(CameraSettingsResponse.Failure) {
      httpClient
        .get(urlSupport.camera.settings) {
          featureFlagsHeader()
          additionalRequestConfiguration()
        }.body()
    }
  }
}
