package it.neckar.bioexp.rest.client

import it.neckar.bioexp.rest.BioExPApiConstants
import it.neckar.commons.js.CookieName
import it.neckar.open.http.Url
import it.neckar.projects.bioexp.BioExPPorts
import it.neckar.projects.bioexp.BioExpService
import it.neckar.projects.common.Port
import it.neckar.rest.jwt.JwtCookies

/**
 * Creates URLs for the BioExp services/ui
 */
class UrlSupport internal constructor(
  /**
   * The usage type
   */
  val usage: Usage,

  /**
   * Creates a URL for a relative path
   */
  val createUrl: (service: BioExpService, relativePath: Url.Relative, portForDev: Port) -> Url.RelativeAppender<*>,

  ) {

  val detection: DetectionServiceUrls = DetectionServiceUrls()

  inner class DetectionServiceUrls : ServiceUrls {
    override val service: BioExpService = BioExpService.Detection

    override val portForDev: Port = Port(BioExPPorts.detectionService)
    override val heartbeat: Url.RelativeAppender<*> = createUrl(service, BioExPApiConstants.Detection.heartbeatUrl, portForDev)
    override val baseUrl: Url.RelativeAppender<*> = createUrl(service, BioExPApiConstants.Detection.basePath, portForDev)


    val detectRBC: Url.RelativeAppender<*> = createUrl(service, BioExPApiConstants.Detection.detectRBC, portForDev)
    val detectRBCSOP1: Url.RelativeAppender<*> = createUrl(service, BioExPApiConstants.Detection.detectRBCSOP1, portForDev)
    val detectEC: Url.RelativeAppender<*> = createUrl(service, BioExPApiConstants.Detection.detectEC, portForDev)
    val detectRBCSOP3: Url.RelativeAppender<*> = createUrl(service, BioExPApiConstants.Detection.detectSOP3, portForDev)
    val detectSOP5: Url.RelativeAppender<*> = createUrl(service, BioExPApiConstants.Detection.detectSOP5, portForDev)
  }


  val storage: StorageServiceUrls = StorageServiceUrls()

  inner class StorageServiceUrls : ServiceUrls {
    override val service: BioExpService = BioExpService.Storage

    override val portForDev: Port = Port(BioExPPorts.storageService)
    override val heartbeat: Url.RelativeAppender<*> = createUrl(service, BioExPApiConstants.Storage.heartbeatUrl, portForDev)
    override val baseUrl: Url.RelativeAppender<*> = createUrl(service, BioExPApiConstants.Storage.basePath, portForDev)


    val uploadFile: Url.RelativeAppender<*> = createUrl(service, BioExPApiConstants.Storage.uploadFile, portForDev)
    val uploadFeedback: Url.RelativeAppender<*> = createUrl(service, BioExPApiConstants.Storage.uploadFeedback, portForDev)
  }

  val camera: CameraServiceUrls = CameraServiceUrls()

  inner class CameraServiceUrls: ServiceUrls {
    override val service: BioExpService = BioExpService.Camera

    override val portForDev: Port = Port(BioExPPorts.cameraService)
    override val heartbeat: Url.RelativeAppender<*> = createUrl(service, BioExPApiConstants.Camera.heartbeatUrl, portForDev)
    override val baseUrl: Url.RelativeAppender<*> = createUrl(service, BioExPApiConstants.Camera.basePath, portForDev)

    val captureImage: Url.RelativeAppender<*> = createUrl(service, BioExPApiConstants.Camera.capture, portForDev)
    val settings: Url.RelativeAppender<*> = createUrl(service, BioExPApiConstants.Camera.settings, portForDev)
  }


  companion object {
    fun forClient(
      /**
       * The current deployment zone
       */
      deploymentZone: DeploymentZone.Client,

      /**
       * Creates a URL for a relative path.
       * Should only be provided for tests
       */
      createUrl: (relativePath: Url.Relative, portForDev: Port) -> Url.RelativeAppender<*> = { relativePath, portForDev ->
        calculateBaseUrlForUsageInClient(deploymentZone, portForDev) + relativePath
      },

      ): UrlSupport {
      return UrlSupport(Usage.WebClient) { service, relativePath, portForDev ->
        createUrl(relativePath, portForDev)
      }
    }

    fun forService(
      /**
       * The current deployment zone
       */
      deploymentZone: DeploymentZone.Service,
      createUrl: (service: BioExpService, relativePath: Url.Relative, portForDev: Port) -> Url.RelativeAppender<*> = { service, relativePath, portForDev ->
        calculateBaseUrlForUsageInService(deploymentZone, service, portForDev) + relativePath
      },
    ): UrlSupport {
      return UrlSupport(Usage.Service) { service: BioExpService, relativePath: Url.Relative, portForDev: Port ->
        createUrl(service, relativePath, portForDev)
      }
    }

    /**
     * Calculates the base URL (hostname + port)
     */
    fun calculateBaseUrl(usage: Usage, deploymentZone: DeploymentZone.Client?, serviceDeploymentZone: DeploymentZone.Service?, service: BioExpService, portForDev: Port): Url.RelativeAppender<*> {
      return when (usage) {
        Usage.WebClient -> calculateBaseUrlForUsageInClient(requireNotNull(deploymentZone), portForDev)
        Usage.Service -> calculateBaseUrlForUsageInService(requireNotNull(serviceDeploymentZone), service, portForDev)
      }
    }

    /**
     * Returns the base URL - depending on the environment (dev or production)
     *
     * Returns an absolute URL with a trailing "/"
     */
    fun calculateBaseUrlForUsageInClient(
      deploymentZone: DeploymentZone.Client,
      portForDev: Port,
    ): Url.RelativeAppender<*> {
      return when (deploymentZone) {
        /**
         * Use the local development services - with different ports
         */
        DeploymentZone.Client.LocalDevelopmentDocker, DeploymentZone.Client.LocalDevelopment -> Url.Absolute("http://localhost:$portForDev/")

        /**
         * Uses nginx to proxy the requests to the correct service
         */
        DeploymentZone.Client.Production, DeploymentZone.Client.Cellcount, DeploymentZone.Client.Playground -> Url.root
      }
    }

    /**
     * Creates the URLs for usage in services (to access other services)
     */
    @Suppress("HttpUrlsUsage")
    fun calculateBaseUrlForUsageInService(
      deploymentZone: DeploymentZone.Service,
      service: BioExpService,
      portForDev: Port,
    ): Url.Absolute {
      return when (deploymentZone) {
        DeploymentZone.Service.LocalDevelopment -> Url.Absolute("http://localhost:$portForDev/")
        DeploymentZone.Service.Docker -> Url.Absolute("http://${service.serviceName}:$portForDev/")
      }
    }
  }

  /**
   * The usage type of the [UrlSupport]
   */
  enum class Usage {
    /**
     * For usage in the web client (react)
     */
    WebClient,

    /**
     * For usage in the microservices (on the server)
     */
    Service
  }


  interface ServiceUrls {
    val service: BioExpService

    /**
     * The port used for dev connections
     */
    val portForDev: Port

    /**
     * The URL for the heartbeat
     */
    val heartbeat: Url.RelativeAppender<*>

    /**
     * The base url for the service
     */
    val baseUrl: Url.RelativeAppender<*>
  }

  object Cookies {
    /**
     * Cookie that contains the JWS access token - used for the PDF Preview service
     */
    val accessToken: CookieName = JwtCookies.accessToken
  }
}
