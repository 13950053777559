package store.actions

import it.neckar.common.redux.ChangeType
import store.BioExpUiState
import store.ImageState
import store.StateAction

/**
 * sets the ImageState
 */
class ImageAction(val newState: ImageState) : StateAction {
  override val type: ChangeType = ChangeType.HistoryReset

  override fun BioExpUiState.updateState(): BioExpUiState {
    return this.copy(imageState = newState)
  }
}
