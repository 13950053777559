package components.table

import it.neckar.commons.kotlin.js.safeGet
import it.neckar.react.common.*
import it.neckar.react.common.button.*
import it.neckar.react.common.table.*
import it.neckar.react.common.toast.*
import kotlinx.html.InputType
import kotlinx.html.js.onChangeFunction
import org.w3c.dom.HTMLInputElement
import react.*
import react.dom.*
import web.navigator.navigator

/**
 * Table that shows the results of the detection
 */
val ResultsTableImagePreview: FC<ResultsTableImagePreviewProps> = fc("ResultsTableImagePreview") { props ->

  val (tableRows, setTableRows) = useState(props::tableRows.safeGet())

  val (newImageNames, setNewImageNames) = useState(props::tableRows.safeGet().map { it.imageName })

  div {
    actionButton(ButtonIcon.centered(FontAwesomeIcons.copy), action = {
      val tableString = props::tableRows.safeGet().joinToString("\n") { "${it.imageName}\t${it.rbcCount}\t${it.percentBurst}" }
      //prepend header to tableString
      navigator.clipboard.writeText("Image\tRBC\t% Burst\n$tableString")
      notifySuccess("Table copied to clipboard")
    }) {
      +" Copy Table"
    }

    val imageNamesColumn: TableDataColumn<ImageCellTableRow> = TableDataColumn("idHeaderColumn", "Image") {
      {
        div("popover-wrapper") {
          a(href = it.imageData, target = "_blank") {
            div("popover-title") {
              +it.imageName
            }
            div("popover-content") {
              p("popover-message") {
                +"Detection Result for ${it.imageName}"
              }
              img(
                src = it.imageData
              ) {
                attrs {
                  width = "800px"
                  height = "auto"
                }
              }
            }
          }
        }
      }
    }

    val detectionsRbcColumn: TableHeaderColumn<ImageCellTableRow> = TableHeaderColumn("rbcColumn", "RBC") {
      {
        p {
          +it.rbcCount.toString()
        }
      }
    }

    val editColumn: TableHeaderColumn<ImageCellTableRow> = TableHeaderColumn("editColumn", "") { tableRow ->
      {
        div() {
          modalButton(
            idOfModal = "editImageNameModal${tableRow.imageIndex}",
            icon = FontAwesomeIcons.edit,
            classes = "btn btn-primary",
            buttonContent = {}
          )
        }
      }
    }

    val percentColumn: TableDataColumn<ImageCellTableRow> = TableDataColumn("detectionsColumn", "Burst") {
      {
        p {
          +it.percentBurst
        }
      }
    }

    val indexColumn: TableDataColumn<ImageCellTableRow> = TableDataColumn("indexColumn", "") {
      {
        p {
          +"${it.imageIndex + 1}"
        }
      }
    }

    val initiallySortedBy: SortedByFunction<ImageCellTableRow> = SortedByFunction(
      sortedBy = SortedBy.SortedAscending,
      sortedColumn = imageNamesColumn,
    )

    div("mt-3 bg-white") {
      table(
        columns = listOf(indexColumn, editColumn, imageNamesColumn, detectionsRbcColumn, percentColumn),
        entries = tableRows,
        sortedByFunction = useState(initiallySortedBy)
      )
    }

    div("text-black") {
      tableRows.map { tableRow ->
        confirmationModal(
          id = "editImageNameModal${tableRow.imageIndex}",
          title = "Edit Image Name",
          cancelButtonText = "Cancel",
          confirmationButtonText = "Save",
          onConfirm = {
            setTableRows(tableRows.toMutableList().also {
              it[tableRows.indexOf(tableRow)].imageName = newImageNames[tableRow.imageIndex]
            })
            props::onImageNameChange.safeGet()(tableRows)
          },
        )
        {
          input(type = InputType.text, classes = "form-control") {
            attrs {
              value = newImageNames[tableRow.imageIndex]
              onChangeFunction = { event ->
                val name = (event.target as HTMLInputElement).value
                setNewImageNames(newImageNames.toMutableList().also {
                  it[tableRow.imageIndex] = name
                })
              }
            }
          }
        }
      }
    }
  }
}

external interface ResultsTableImagePreviewProps : Props {
  var tableRows: List<ImageCellTableRow>
  var onImageNameChange: (List<ImageCellTableRow>) -> Unit
}

data class ImageCellTableRow(
  var imageIndex: Int,
  var imageData: String,
  var imageName: String,
  val rbcCount: Int,
  val rbcgCount: Int,
  val percentBurst: String,
)
