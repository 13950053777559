package components.sop1

import components.SOPSelectionHeader
import components.ThresholdSelector
import it.neckar.react.common.upload.*
import kotlinx.css.*
import react.*
import react.dom.*
import services.UiActions

/**
 * main page where the image for SOP1 can be selected
 */
val SOP1ImageSelectionPage: FC<Props> = fc("ImageSelectionPage") { props ->

  div("ps-0 container-fluid text-white") {

    SOPSelectionHeader {}

    div("container pt-5 py-3") {

      h1("text-center fw-bold pt-5") {
        +"RBC-EC Adhesion Detection"
      }

      div("row") {

        div("mt-3") {
          ImageUploadField {
            attrs {
              fieldText = "Select or Drop Image (.jpg, .jpeg, .png)"
              uploadFunction = { file -> UiActions.loadFileSOP1(file) }
              height = LinearDimension("20rem")
              acceptableFileTypes = listOf(".jpg", ".jpeg", ".png")
            }
          }
        }
      }

      ThresholdSelector {
        attrs {
          min = 0.5
          max = 0.95
          step = 0.01
          defaultValue = 0.75
        }
      }

      div("mt-5 fs-4") {
        h2("fw-bold") {
          +"Instructions"
        }
        ol {
          li {
            +"Drop or select the image to run EC and RBC detection on"
          }
          li {
            +"The image will be uploaded and cell types will be detected automatically"
          }
          li {
            +"Results will be generated and presented"
          }
        }

      }
    }
  }
}
