package components

import it.neckar.react.common.button.*
import kotlinx.html.id
import react.*
import react.dom.*
import services.UiActions
import store.DefaultStateSOP1
import store.DefaultStateSOP2
import store.DefaultStateSOP3
import store.DefaultStateSOP5
import store.useSelector

/**
 * Header to select the SOP
 */
val SOPSelectionHeader: FC<Props> = fc("SOPSelectionHeader") { props ->
  val imageState = useSelector { imageState }

  div("container-fluid header py-2") {
    attrs.id = "header"

    div("row") {
      div("d-flex flex-row align-items-center") {
        when (imageState) {
          DefaultStateSOP1 -> {
            div("px-2") {
              actionButton(
                ButtonIcon.centered(""), "btn btn-primary", action = suspend {
                  UiActions.setSOP1DefaultState()
                }) {
                +"SOP 1"
              }
            }

            div("px-2") {
              actionButton(
                ButtonIcon.centered(""), "btn btn-secondary", action = suspend {
                  UiActions.setSOP2DefaultState()
                }) {
                +"RBC Shape"
              }
            }

            div("px-2") {
              actionButton(ButtonIcon.centered(""), "btn btn-secondary", action = suspend {
                UiActions.setSOP3DefaultState()
              }) {
                +"SOP 3"
              }
            }

            div("px-2") {
              actionButton(ButtonIcon.centered(""), "btn btn-secondary", action = suspend {
                UiActions.setSOP5DefaultState()
              }) {
                +"SOP 5"
              }
            }

            div("px-2") {
              +"Send additional Feedback to"
            }
            a("mailto:cellcount@neckar.it", classes = "text-white") {
              +"cellcount@neckar.it"
            }
          }

          DefaultStateSOP2 -> {
            div("px-2") {
              actionButton(
                ButtonIcon.centered(""), "btn btn-secondary", action = suspend {
                  UiActions.setSOP1DefaultState()
                }) {
                +"SOP 1"
              }
            }

            div("px-2") {
              actionButton(
                ButtonIcon.centered(""), "btn btn-primary", action = suspend {
                  UiActions.setSOP2DefaultState()
                }) {
                +"RBC Shape"
              }
            }

            div("px-2") {
              actionButton(ButtonIcon.centered(""), "btn btn-secondary", action = suspend {
                UiActions.setSOP3DefaultState()
              }) {
                +"SOP 3"
              }
            }

            div("px-2") {
              actionButton(ButtonIcon.centered(""), "btn btn-secondary", action = suspend {
                UiActions.setSOP5DefaultState()
              }) {
                +"SOP 5"
              }
            }

            div("px-2") {
              +"Send additional Feedback to"
            }
            a("mailto:cellcount@neckar.it", classes = "text-white") {
              +"cellcount@neckar.it"
            }
          }

          DefaultStateSOP3 -> {
            div("px-2") {
              actionButton(
                ButtonIcon.centered(""), "btn btn-secondary", action = suspend {
                  UiActions.setSOP1DefaultState()
                }) {
                +"SOP 1"
              }
            }

            div("px-2") {
              actionButton(ButtonIcon.centered(""), "btn btn-secondary", action = suspend {
                UiActions.setSOP2DefaultState()
              }) {
                +"RBC Shape"
              }
            }

            div("px-2") {
              actionButton(ButtonIcon.centered(""), "btn btn-primary", action = suspend {
                UiActions.setSOP3DefaultState()
              }) {
                +"SOP 3"
              }
            }

            div("px-2") {
              actionButton(ButtonIcon.centered(""), "btn btn-secondary", action = suspend {
                UiActions.setSOP5DefaultState()
              }) {
                +"SOP 5"
              }
            }

            div("px-2") {
              +"Send additional Feedback to"
            }
            a("mailto:cellcount@neckar.it", classes = "text-white") {
              +"cellcount@neckar.it"
            }
          }

          DefaultStateSOP5 -> {
            div("px-2") {
              actionButton(
                ButtonIcon.centered(""), "btn btn-secondary", action = suspend {
                  UiActions.setSOP1DefaultState()
                }) {
                +"SOP 1"
              }
            }

            div("px-2") {
              actionButton(ButtonIcon.centered(""), "btn btn-secondary", action = suspend {
                UiActions.setSOP2DefaultState()
              }) {
                +"RBC Shape"
              }
            }

            div("px-2") {
              actionButton(ButtonIcon.centered(""), "btn btn-secondary", action = suspend {
                UiActions.setSOP3DefaultState()
              }) {
                +"SOP 3"
              }
            }

            div("px-2") {
              actionButton(ButtonIcon.centered(""), "btn btn-primary", action = suspend {
                UiActions.setSOP5DefaultState()
              }) {
                +"SOP 5"
              }
            }

            div("px-2") {
              +"Send additional Feedback to"
            }
            a("mailto:cellcount@neckar.it", classes = "text-white") {
              +"cellcount@neckar.it"
            }
          }

          else -> {}
        }
      }
    }
  }
}
