package components

import it.neckar.common.featureflags.FeatureFlag
import it.neckar.react.common.*
import it.neckar.react.common.featureflags.*
import it.neckar.react.common.redux.*
import react.*
import store.store

val BioExpRoot: FC<Props> = fc("BioExpRoot") { props ->

  FeatureFlagsContextComponent {
    //This component throws an exception if the corresponding feature flag is set
    BuggyComponentFromFeatureFlags {
      attrs {
        featureFlag = FeatureFlag.throwException
      }
    }

    //Provides the store. Use the hook useSelector() to access the store
    provider(store) {
      BioExpMainComponent {}
    }

  }
}
