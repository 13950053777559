package it.neckar.bioexp.rest.client

import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.request.*
import io.ktor.http.*
import it.neckar.bioexp.rest.message.FeedbackUploadRequest
import it.neckar.bioexp.rest.message.FileUploadRequest
import it.neckar.bioexp.rest.message.FileUploadResponse
import it.neckar.ktor.client.featureFlagsHeader
import it.neckar.ktor.client.safeTryFetch
import it.neckar.ktor.client.*
/**
 * Client to access the bio exp services
 */
class StorageServiceClient(val httpClient: HttpClient, val urlSupport: UrlSupport) {
  /**
   * Calls file upload for the given image path.
   */
  suspend fun upload(fileRequest: FileUploadRequest, additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {}): FileUploadResponse {
    return safeTryFetch(FileUploadResponse.Failure) {
      httpClient
        .post(urlSupport.storage.uploadFile) {
          featureFlagsHeader()
          setBody(fileRequest)
          contentType(ContentType.Application.Json)
          additionalRequestConfiguration()
        }.body()
    }
  }

  /**
   * Uploads feedback txt file
   */
  suspend fun uploadFeedback(fileRequest: FeedbackUploadRequest, additionalRequestConfiguration: HttpRequestBuilder.() -> Unit = {}): FileUploadResponse {
    return safeTryFetch(FileUploadResponse.Failure) {
      httpClient
        .post(urlSupport.storage.uploadFeedback) {
          featureFlagsHeader()
          setBody(fileRequest)
          contentType(ContentType.Application.Json)
          additionalRequestConfiguration()
        }.body()
    }
  }
}
