package it.neckar.bioexp.rest.message

import it.neckar.open.kotlin.serializers.ByteArraySerializer
import it.neckar.rest.RestRequest
import kotlinx.serialization.Serializable

/**
 * Request sent to the Storage Service to upload a file
 */
@Serializable
data class FileUploadRequest(
  /**
   * The name of the file to upload
   */
  val fileName: String,

  /**
   * The data of the file to upload
   */
  val fileData: @Serializable(with = ByteArraySerializer::class) ByteArray,
) : RestRequest {
  override fun equals(other: Any?): Boolean {
    if (this === other) return true
    if (other == null || this::class != other::class) return false

    other as FileUploadRequest

    if (fileName != other.fileName) return false
    if (!fileData.contentEquals(other.fileData)) return false

    return true
  }

  override fun hashCode(): Int {
    var result = fileName.hashCode()
    result = 31 * result + fileData.contentHashCode()
    return result
  }
}
