package components.sop2

import com.meistercharts.algorithms.layers.barchart.CategorySeriesModelColorsProvider
import com.meistercharts.charts.BarChartGroupedGestalt
import com.meistercharts.model.category.Category
import com.meistercharts.model.category.CategorySeriesModel
import com.meistercharts.model.category.DefaultCategorySeriesModel
import com.meistercharts.model.category.DefaultSeries
import com.meistercharts.range.ValueRange
import com.meistercharts.react.meistercharts
import it.neckar.bioexp.rest.detect.CellType
import it.neckar.bioexp.rest.detect.DetectedCell
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.open.i18n.TextKey
import it.neckar.open.kotlin.lang.asProvider
import it.neckar.open.unit.other.Sorted
import it.neckar.open.unit.other.pct
import react.*
import react.dom.*

/**
 * Bar chart for the results of the detection
 */
val SOP2ResultsChart: FC<BarChartProps> = fc("SOP2ResultsChart") { props ->
  val cellTypes = props::cellTypes.safeGet()
  val detectedCells: List<DetectedCell> = props::detectedCells.safeGet()

  /**
   * How many objects of each class have been detected
   */
  val detectedCellCounts: @Sorted(by = "Natural order of CellType") List<@pct Double> = useMemo(detectedCells) {
    val counts = detectedCells.groupingBy { it.cellType }.eachCount()

    CellType.entries.map { cellType ->
      counts[cellType]?.toDouble() ?: 0.0
    }
  }

  val categoryModel: CategorySeriesModel = useMemo(detectedCellCounts) {
    DefaultCategorySeriesModel(
      categories = cellTypes.map { cellType -> Category(TextKey.simple(cellType.name)) },
      series = listOf(
        DefaultSeries(
          "Detection Count",
          detectedCellCounts
        ),
      )
    )
  }

  val gestalt: BarChartGroupedGestalt = useMemo {
    val barChartGroupedGestalt = BarChartGroupedGestalt()
    barChartGroupedGestalt.style.showGrid = true

    // start with visible value-labels
    barChartGroupedGestalt.groupedBarsPainter.configuration.showValueLabel = true
    barChartGroupedGestalt.groupedBarsPainter.configuration.valueLabelColor = com.meistercharts.color.Color.black

    barChartGroupedGestalt.groupedBarsPainter.configuration.colorsProvider = CategorySeriesModelColorsProvider.onlyCategoryColorsProvider(
      listOf(
        com.meistercharts.color.Color("#ff0000").asProvider(), // Red
        com.meistercharts.color.Color("#00ff00").asProvider(), // Green
        com.meistercharts.color.Color("#0000ff").asProvider(), // Blue
        com.meistercharts.color.Color("#FFFF00").asProvider(), // Yellow
        com.meistercharts.color.Color("#FF00FF").asProvider(), // Magenta
        com.meistercharts.color.Color("#00FFFF").asProvider(), // Cyan
        com.meistercharts.color.Color("#000000").asProvider(), // Black
      )
    )
    barChartGroupedGestalt
  }

  /**
   * Updates the gestalt if necessary
   */
  useMemo(categoryModel, detectedCellCounts) {
    gestalt.configuration.categorySeriesModel = categoryModel
    gestalt.style.valueRange = ValueRange.linear(0.0, detectedCellCounts.max() * 1.05)
  }


  div("bg-white") {
    meistercharts(
      description = "RBC Bar Meisterchart",
      gestalt = gestalt,
      callMarkAsDirty = true
    )
  }
}


external interface BarChartProps : Props {
  /**
   * The supported cell types
   */
  var cellTypes: List<CellType>

  /**
   * The detected cells
   */
  var detectedCells: List<DetectedCell>
}
