package store.actions

import it.neckar.common.redux.ChangeType
import it.neckar.rest.jwt.JwtTokenPair
import store.BioExpUiState
import store.StateAction

/**
 * sets the user as logged in. copied from lizergy
 */
data class LoggedInAction(
  /**
   * The username to refresh the JWS token
   */
  val loggedInUser: String,

  /**
   * The password to refresh the JWS token
   */
  val password: String,

  /**
   * The jws tokens
   */
  val jwtTokenPair: JwtTokenPair,
) : StateAction {
  override val type: ChangeType = ChangeType.HistoryReset

  override fun BioExpUiState.updateState(): BioExpUiState {
    return login(loggedInUser, password, jwtTokenPair)
  }
}
