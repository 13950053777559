package store

import it.neckar.bioexp.rest.client.DeploymentZone
import it.neckar.rest.jwt.JwtTokenPair
import it.neckar.user.UserLoginName

/**
 * Represents the UI state for the BioExP UI
 */
data class BioExpUiState(
  /**
   * The server the application is running on.
   * Can be used to determine the base URL for API calls and if this is production or development
   */
  val deploymentZone: DeploymentZone.Client,
  /**
   * The login state
   */
  val loginState: LoginState = NotYetLoggedIn,

  /**
   * The state for an uploaded image
   */
  val imageState: ImageState = DefaultStateSOP2,

  /**
   * The state for the object detection threshold
   */
  val thresholdState: ThresholdState = SetThresholdState(0.75),
) {

  /**
   * Update counter that is automatically incremented in init
   */
  var updateCounter: Int = -1

  init {
    //Automatically increase the update counter
    updateCounter++
  }

  fun login(
    loggedInUser: String,
    password: String,
    tokenPair: JwtTokenPair,
  ): BioExpUiState {
    return copy(loginState = LoggedInState(UserLoginName(loggedInUser), password, tokenPair))
  }
}
