package it.neckar.bioexp.rest

import it.neckar.commons.js.CookieName
import it.neckar.open.http.Url
import it.neckar.open.http.UrlParameterName
import it.neckar.rest.jwt.JwtCookies

/**
 * Contains all constants for the BioExP API
 */
object BioExPApiConstants {
  private val basePath: Url.Relative = Url.relative("api")

  /**
   * Contains the cookie names
   */
  object Cookies {
    /**
     * Cookie that contains the JWS access token
     */
    val accessToken: CookieName = JwtCookies.accessToken
  }

  /**
   * Contains constants for parameters
   */
  object Parameters {
    val experimentId: UrlParameterName = UrlParameterName("experimentId")
    val stepId: UrlParameterName = UrlParameterName("stepId")
    val pumpId: UrlParameterName = UrlParameterName("pumpId")
    val reservoirId: UrlParameterName = UrlParameterName("reservoirId")
  }

  /**
   * Constants for the detection service
   */
  object Detection {
    val basePath: Url.Relative = BioExPApiConstants.basePath + "detection"

    val heartbeatUrl: Url.Relative = basePath + "heartbeat"

    val detectRBC: Url.Relative = basePath + "detect-rbc"
    val detectRBCSOP1: Url.Relative = basePath + "detect-sop1"
    val detectEC: Url.Relative = basePath + "detect-ec"
    val detectSOP3: Url.Relative = basePath + "detect-sop3"
    val detectSOP5: Url.Relative = basePath + "detect-sop5"
  }

  /**
   * Constants for the storage service
   */
  object Storage {
    val basePath: Url.Relative = BioExPApiConstants.basePath + "storage"

    val heartbeatUrl: Url.Relative = basePath + "heartbeat"

    /**
     * Path to upload a file
     */
    val uploadFile: Url.Relative = basePath + "upload"

    val uploadFeedback: Url.Relative = basePath + "upload-feedback"
  }

  /**
   * Constants for the pre-annotation service
   */
  object PreAnnotation {
    val basePath: Url.Relative = BioExPApiConstants.basePath + "annotations"

    val heartbeatUrl: Url.Relative = basePath + "heartbeat"

    val predict: Url.Relative = basePath + "predict"
    val health: Url.Relative = basePath + "health"
    val setup: Url.Relative = basePath + "setup"
    val metrics: Url.Relative = basePath + "metrics"
  }

  object Camera {
    val basePath: Url.Relative = BioExPApiConstants.basePath + "camera"

    val heartbeatUrl: Url.Relative = basePath + "heartbeat"

    val capture: Url.Relative = basePath + "capture"
    val metrics: Url.Relative = basePath + "metrics"
    val settings: Url.Relative = basePath + "settings"
  }

  object Hub {
    val basePath: Url.Relative = BioExPApiConstants.basePath + "hub"

    val heartbeatUrl: Url.Relative = basePath + "heartbeat"

    val processBasePath: Url.Relative = basePath + "process"

    val emergencyStop: Url.Relative = processBasePath + "emergency-stop"

    val liveView: Url.Relative = basePath + "live-view"
    val systemStatus: Url.Relative = basePath + "system-status"

    val temperatureControl: Url.Relative = basePath + "temperature-control"
    val pumpControl: Url.Relative = basePath + "pump-control"

    object Experiments {
      val experiments: Url.Relative = basePath + "experiments"
      val experiment: Url.Relative = experiments + Parameters.experimentId
      fun experiment(experimentId: String): Url.Relative = experiments + experimentId

      val experimentResults: Url.Relative = experiment + "results"
      fun experimentResults(experimentId: String): Url.Relative = experiments + experimentId + "results"

      val experimentNote: Url.Relative = experiment + "note"
      fun experimentNote(experimentId: String): Url.Relative = experiments + experimentId + "note"

      val experimentLog: Url.Relative = experiment + "log"
      fun experimentLog(experimentId: String): Url.Relative = experiments + experimentId + "log"

      val experimentMetadata: Url.Relative = experiment + "metadata"
      fun experimentMetadata(experimentId: String): Url.Relative = experiments + experimentId + "metadata"

      val cancelExperiment: Url.Relative = experiment + "cancel"
      fun cancelExperiment(experimentId: String): Url.Relative = experiments + experimentId + "cancel"
    }

    object Steps {
      val steps: Url.Relative = Experiments.experiment + "steps"
      fun steps(experimentId: String): Url.Relative = Experiments.experiments + experimentId + "steps"

      val step: Url.Relative = steps + Parameters.stepId
      fun step(experimentId: String, stepId: String): Url.Relative = Experiments.experiments + experimentId + "steps" + stepId

      val startStep: Url.Relative = step + "start"
      fun startStep(experimentId: String, stepId: String): Url.Relative = Experiments.experiments + experimentId + "steps" + stepId + "start"

      val completeStep: Url.Relative = step + "complete"
      fun completeStep(experimentId: String, stepId: String): Url.Relative = Experiments.experiments + experimentId + "steps" + stepId + "complete"

      val skipStep: Url.Relative = step + "skip"
      fun skipStep(experimentId: String, stepId: String): Url.Relative = Experiments.experiments + experimentId + "steps" + stepId + "skip"

      val cancelStep: Url.Relative = step + "cancel"
      fun cancelStep(experimentId: String, stepId: String): Url.Relative = Experiments.experiments + experimentId + "steps" + stepId + "cancel"

      val forceCompleteStep: Url.Relative = step + "force-complete"
      fun forceCompleteStep(experimentId: String, stepId: String): Url.Relative = Experiments.experiments + experimentId + "steps" + stepId + "force-complete"

      val resetStep: Url.Relative = step + "reset"
      fun resetStep(experimentId: String, stepId: String): Url.Relative = Experiments.experiments + experimentId + "steps" + stepId + "reset"
    }

    object Manual {
      val captureImageManual: Url.Relative = basePath + "capture-image-manual"
      val setTemperatureOverride: Url.Relative = basePath + "set-temperature"
      val startPump: Url.Relative = basePath + "pump" + Parameters.pumpId + "start"
      fun startPump(pumpId: String): Url.Relative = basePath + "pump" + pumpId + "start"
      val stopPump: Url.Relative = basePath + "pump" + Parameters.pumpId + "stop"
      fun stopPump(pumpId: String): Url.Relative = basePath + "pump" + pumpId + "stop"
      val setReservoirLevel: Url.Relative = basePath + "reservoir" + Parameters.reservoirId + "set-level"
      fun setReservoirLevel(reservoirId: String): Url.Relative = basePath + "reservoir" + reservoirId + "set-level"
    }

  }
}
