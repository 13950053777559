package it.neckar.bioexp.rest.client

import it.neckar.projects.bioexp.BioExPHost
import it.neckar.projects.bioexp.BioExPPorts

object DeploymentZone {
  /**
   * The deployment zone the service is running in
   */
  enum class Service {
    /**
     * Localhost / development - *no* docker
     */
    LocalDevelopment,

    /**
     * Deployed within docker (maybe on localhost or production)
     */
    Docker,
  }

  /**
   * Describes the environment the *client* application runs in
   */
  enum class Client {
    /**
     * Localhost / development - *no* docker
     */
    LocalDevelopment,

    /**
     * Deployed within docker.
     *
     * ### UI:
     * Only relevant when running on the developers machine (localhost).
     * On the server there should always be used one of the other modes.
     *
     * ### Services
     * This mode will be selected on all severs and if executed on the developers machine (localhost) using docker
     */
    LocalDevelopmentDocker,

    /**
     * Playground server (Staging)
     */
    Playground,

    /**
     * Cellcount server (non-bioexp UI)
     */
    Cellcount,

    /**
     * Production.
     * Attention: Must be checked for last!
     */
    Production,
    ;

    /**
     * Returns true if docker is used
     */
    fun inDocker(): Boolean {
      return this != LocalDevelopmentDocker
    }

    /**
     * Returns true if the current server instance is localhost
     */
    fun isLocalhost(): Boolean {
      return this == LocalDevelopment
    }

    fun isProduction(): Boolean {
      return this == Production || this == Cellcount
    }

    fun isCellcount(): Boolean {
      return this == Cellcount
    }

    companion object {
      /**
       * Returns the server for the given URL (of the web application the client is running in)
       */
      fun getClientDeploymentZoneFromWindowLocation(windowLocationHref: String): Client {
        return when {
          windowLocationHref.startsWith("https://${BioExPHost.Production.hostname}") -> Production
          //windowLocationHref.startsWith("https://${BioExPHost.ProductionAI.hostname}") -> Production
          windowLocationHref.startsWith("https://${BioExPHost.CellCount.hostname}") -> Cellcount
          //windowLocationHref.startsWith("https://${BioExPHost.CellCountAI.hostname}") -> Cellcount
          windowLocationHref.startsWith("https://${BioExPHost.Playground.hostname}") -> Playground
          //windowLocationHref.startsWith("https://${BioExPHost.PlaygroundAI.hostname}") -> Playground
          windowLocationHref.startsWith("http://localhost:${BioExPPorts.Ui.docker}") -> LocalDevelopmentDocker
          windowLocationHref.startsWith("http://localhost:${BioExPPorts.Ui.devServer}") -> LocalDevelopment
          else -> throw IllegalStateException("Could not identify server for url $windowLocationHref")
        }
      }
    }
  }

}
