package components.sop3

import it.neckar.commons.kotlin.js.safeGet
import it.neckar.open.collections.swap
import it.neckar.react.common.*
import it.neckar.react.common.button.*
import react.*
import react.dom.*
import services.UiActions
import web.file.File
import web.file.FileList

val SOP3AlignmentPage: FC<SOP3AlignmentPageProps> = fc("AlignmentPage") { props ->
  val (files, setFiles) = useState(props::files.safeGet())

  val (fileList, setFileList) = useState(
    files.toMutableList()
  )

  /**
   * change the indes of the file list when its changed with up and down buttons
   * simply swap the files at the given indices
   */
  val indexChanged: (Int, Int) -> Unit = useCallback(fileList) { newIndex, oldIndex ->
    println("Index changed from $oldIndex to $newIndex")
    val updatedList = fileList.toMutableList()
    updatedList.swap(newIndex, oldIndex)
    setFileList(updatedList)
  }


  /**
   * change the indes of the file list when its changed via drag and drop
   *
   * move the file from oldIndex to newIndex
   * move all files "below" the newIndex down by one until the oldIndex is reached
   */
  val indexChangedDragAndDrop: (Int, Int) -> Unit = useCallback(fileList) { newIndex, oldIndex ->
    println("Index changed from $oldIndex to $newIndex")
    val updatedList = fileList.toMutableList()

    //remove file from old index
    val file = updatedList.removeAt(oldIndex)


    // if newIndex is greater than oldIndex, the file is moved "down" in the list
    if(newIndex > oldIndex){
      //move all files "above" the newIndex up by one until the oldIndex is reached
      for(i in oldIndex downTo newIndex+1){
        updatedList[i] = updatedList[i-1]
      }
    }
    else {
      //move all files "below" the newIndex down by one until the oldIndex is reached
      for(i in oldIndex until newIndex){
        updatedList[i] = updatedList[i+1]
      }
    }

    //insert file at newIndex
    updatedList.add(newIndex, file)

    setFileList(updatedList)
  }

  div("container-fluid text-white") {
    div("container pt-5 py-3") {
      div("text-center fs-1 d-flex justify-content-between align-items-center") {
        actionButton(
          classes = "btn btn-primary",
          icon = ButtonIcon.centered(FontAwesomeIcons.arrowLeft),
          action = suspend { UiActions.setSOP3DefaultState() }
        ) { }

        div("fw-bold my-0") {
          +"Adjust image order"
        }

        actionButton(
          classes = "btn btn-primary",
          icon = ButtonIcon.centered(FontAwesomeIcons.arrowRight),
          action = suspend { UiActions.uploadFilesSOP3(fileList) }
        ) { }
      }

      div("row mt-3") {
        div {
          fileList.map {
            FileListItem {
              attrs {
                file = it
                index = fileList.indexOf(it)
                onIndexChange = indexChanged
                maxIndex = fileList.size - 1
                onIndexChangeDragAndDrop = indexChangedDragAndDrop
              }
            }
          }
        }
      }
    }
  }
}

external interface SOP3AlignmentPageProps : Props {
  var files: FileList
}

fun FileList.toMutableList(): MutableList<File> {
  val list = mutableListOf<File>()
  for (i in 0 until length) {
    list.add(this[i])
  }
  return list
}

