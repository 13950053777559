package it.neckar.bioexp.rest.message

import it.neckar.rest.RestRequest
import kotlinx.serialization.Serializable

/**
 * Request sent to the Detection Service to detect cells in an image
 */
@Serializable
data class DetectRequest(
  /**
   * The path of the image to detect cells in
   */
  val path: String,

  /**
   * The threshold to use for the detection
   */
  val threshold: Double,

  /**
   * Whether to return the bounding box image
   */
  val returnBoundingBoxImage: Boolean
) : RestRequest
