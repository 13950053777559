package it.neckar.bioexp.rest.message

import it.neckar.open.kotlin.serializers.ByteArraySerializer
import it.neckar.open.kotlin.serializers.DoubleAsIsoDateTimeSerializer
import it.neckar.open.unit.si.ms
import kotlinx.serialization.Serializable

@Serializable
data class CameraCaptureResult(
  val imageData: @Serializable(with = ByteArraySerializer::class) ByteArray,
  val captureTime: @Serializable(with = DoubleAsIsoDateTimeSerializer::class) @ms Double,
) {
  override fun equals(other: Any?): Boolean {
    if (this === other) return true
    if (other == null || this::class != other::class) return false

    other as CameraCaptureResult

    if (!imageData.contentEquals(other.imageData)) return false
    if (captureTime != other.captureTime) return false

    return true
  }

  override fun hashCode(): Int {
    var result = imageData.contentHashCode()
    result = 31 * result + captureTime.hashCode()
    return result
  }
}
