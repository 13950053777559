package components.sop3

import it.neckar.commons.kotlin.js.safeGet
import it.neckar.open.kotlin.lang.substr
import it.neckar.react.common.*
import it.neckar.react.common.FontAwesome.icon
import it.neckar.react.common.button.*
import kotlinx.css.*
import kotlinx.html.Draggable
import kotlinx.html.draggable
import kotlinx.html.js.onDragEndFunction
import kotlinx.html.js.onDragEnterFunction
import kotlinx.html.js.onDragLeaveFunction
import kotlinx.html.js.onDragOverFunction
import kotlinx.html.js.onDragStartFunction
import kotlinx.html.js.onDropFunction
import org.w3c.dom.events.Event
import react.*
import react.dom.*
import react.dom.events.*
import styled.*
import web.file.File
import kotlin.js.Date

val FileListItem: FC<FileListItemProps> = fc("FileListItem") { props ->

  val (dragActive, setDragActive) = useState(false)

  val handleDrop: (Event) -> Unit = useCallback(dragActive, props.onIndexChangeDragAndDrop, props::index.safeGet()) { event ->
    event.preventDefault()
    event.stopPropagation()
    setDragActive(false)

    // Handle the dropped item here, e.g., get the data transferred
    val dataTransfer = (event.unsafeCast<DragEvent<*>>()).dataTransfer
    val draggedIndex = dataTransfer.getData("text").toInt()
    println("currently dragging index: $draggedIndex")
    props.onIndexChangeDragAndDrop(props::index.safeGet(), draggedIndex)
  }

  val handleDrag: (Event) -> Unit = useCallback(dragActive) { event ->
    event.preventDefault()
    event.stopPropagation()
    if (event.type === "dragenter" || event.type === "dragover") {
      setDragActive(true)
    } else if (event.type === "dragleave") {
      setDragActive(false)
    }
  }

  val handleDragStart: (Event) -> Unit = useCallback(props::index.safeGet()) { event ->
    // Set the dragged item index in the dataTransfer
    val dataTransfer = (event.unsafeCast<DragEvent<*>>()).dataTransfer
    dataTransfer.setData("text", props::index.safeGet().toString())
  }

  val handleDragEnd: (Event) -> Unit = useCallback(dragActive) { event ->
    setDragActive(false)
  }

  div("row align-items-center text-white bg-info rounded-3 p-3 my-3 container ${if (dragActive) "border border-red" else ""}") {

    attrs {
      onDragStartFunction = handleDragStart
      onDropFunction = handleDrop
      onDragOverFunction = handleDrag
      onDragEnterFunction = handleDrag
      onDragLeaveFunction = handleDrag
      onDragEndFunction = handleDragEnd
      draggable = Draggable.htmlTrue
    }

    // Buttons column
    div("col-sm-2") {
      div("btn-group-vertical") {
        actionButton(
          classes = "btn btn-primary mx-3 ${if (props::index.safeGet() == 0) "disabled" else ""}",
          icon = ButtonIcon.centered(FontAwesomeIcons.sortUp),
          action = suspend {
            props.onIndexChange(props::index.safeGet() - 1, props::index.safeGet())
          }
        ) { }

        actionButton(
          classes = "btn btn-primary mx-3 mt-1 ${if (props::index.safeGet() == props::maxIndex.safeGet()) "disabled" else ""}",
          icon = ButtonIcon.centered(FontAwesomeIcons.sortDown),
          action = suspend {
            props.onIndexChange(props::index.safeGet() + 1, props::index.safeGet())
          }
        ) { }
      }
    }

    // Text column
    div("col-sm-9 z-0") {
      // format date
      val file = props::file.safeGet(); // Assuming you have a File object
      val lastModifiedDate = Date(file.lastModified);
      val day = lastModifiedDate.getDate().toString().padStart(2, '0');
      val month = (lastModifiedDate.getMonth() + 1).toString().padStart(2, '0'); // January is 0!
      val year = lastModifiedDate.getFullYear().toString().substr(-2); // Get last 2 digits of year#
      val hour = lastModifiedDate.getHours().toString().padStart(2, '0');
      val minute = lastModifiedDate.getMinutes().toString().padStart(2, '0');

      val formattedDate = "$day/$month/$year $hour:$minute";
      +"${props::index.safeGet()} - ${props::file.safeGet().name} - $formattedDate"
    }

    styledDiv {
      css {
        classes.add("col-sm-1")
        cursor = Cursor.grab
      }
      icon(FontAwesomeIcons.gripVertical)
    }
  }
}

external interface FileListItemProps : Props {
  var file: File
  var index: Int
  var maxIndex: Int
  var onIndexChange: (Int, Int) -> Unit
  var onIndexChangeDragAndDrop: (Int, Int) -> Unit
}
