package it.neckar.bioexp.rest.detect

import it.neckar.open.unit.other.pct
import kotlinx.serialization.Serializable
import kotlin.jvm.JvmInline

/**
 * Value class representing the confidence of a detection
 */
@JvmInline
@Serializable
value class DetectionConfidence(val value: @pct Double) {
  operator fun plus(other: DetectionConfidence): DetectionConfidence {
    return DetectionConfidence(this.value + other.value)
  }

  operator fun div(divisor: Double): DetectionConfidence {
    return DetectionConfidence(this.value / divisor)
  }
}
