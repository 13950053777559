package components.sop3

import com.meistercharts.algorithms.layers.linechart.LineStyle
import com.meistercharts.charts.bioexp.BioExPCategoryLineChartGestalt
import com.meistercharts.design.Theme
import com.meistercharts.design.valueAt
import com.meistercharts.model.category.Category
import com.meistercharts.model.category.DefaultCategorySeriesModel
import com.meistercharts.model.category.DefaultSeries
import com.meistercharts.react.meistercharts
import it.neckar.commons.kotlin.js.safeGet
import it.neckar.open.i18n.TextKey
import it.neckar.open.math.FittedSigmoid.fitLogisticModel
import it.neckar.open.math.FittedSigmoid.logistic
import it.neckar.open.provider.MultiProvider
import it.neckar.open.unit.other.pct
import kotlinx.html.ButtonType
import kotlinx.html.InputType
import kotlinx.html.js.onChangeFunction
import org.w3c.dom.HTMLInputElement
import react.*
import react.dom.*
import kotlin.math.max
import kotlin.math.min

/**
 * Line chart for the results of the detection
 */
val SOP3ResultsChart: FC<LineChartProps> = fc("SOP3ResultsChart") { props ->
  @pct val explodedCellsPercent = props::explodedCellsPercent.safeGet()

  // State to hold the learning rate
  val (showSigmoid, setShowSigmoid) = useState(false)

  val (paintLine, setPaintLine) = useState(false)

  val gestalt = useMemo() {
    BioExPCategoryLineChartGestalt()
  }

  val sig: List<Double> = useMemo(explodedCellsPercent){
    val x = explodedCellsPercent.indices.map { it.toDouble() }
    val y = explodedCellsPercent
    val params = fitLogisticModel(x, y)
    x.map { logistic(it, params[0], params[1], params[2]) }.map { min(1.0, max(0.0, it)) }
  }


  val model = useMemo(explodedCellsPercent, sig, showSigmoid, props::names.safeGet()) {
    DefaultCategorySeriesModel(
      List(explodedCellsPercent.size) { index ->
        Category(TextKey.simple(props::names.safeGet()[index]))
      },
      if (showSigmoid) {
        listOf(
          DefaultSeries(
            name = "Amount of burst cells in %",
            values = explodedCellsPercent
          ),
          //DefaultSeries(
          //  name = "Fitted sigmoid",
          //  values = fittedSigmoid
          //),
          DefaultSeries(
            name = "Sigmoid",
            values = sig
          )
        )
      } else {
        listOf(
          DefaultSeries(
            name = "Amount of burst cells in %",
            values = explodedCellsPercent
          )
        )
      }
    )
  }

  useMemo(model, paintLine) {
    gestalt.configuration.categorySeriesModel = model

    if (paintLine) {
      gestalt.gestalt.categoryLinesLayer.configuration.lineStyles = MultiProvider.always(LineStyle(color = Theme.chartColors.valueAt(0), lineWidth = 1.0))
    } else {
      gestalt.gestalt.categoryLinesLayer.configuration.lineStyles = MultiProvider.invoke { index ->
        if (index == 0) {
          LineStyle(color = Theme.chartColors.valueAt(0), lineWidth = 0.001)
        } else {
          LineStyle(color = Theme.chartColors.valueAt(0), lineWidth = 1.0)
        }
      }
    }

    div("bg-white") {
      meistercharts(
        description = "RBC Line Meisterchart",
        gestalt = gestalt,
        callMarkAsDirty = true
      )
    }
  }

  div("accordion mt-2") {
    div("accordion-item") {
      h2("accordion-header mt-0") {
        button(classes = "accordion-button collapsed") {
          attrs {
            type = ButtonType.button
            attributes["data-bs-toggle"] = "collapse"
            attributes["data-bs-target"] = "#collapseOne"
            attributes["aria-expanded"] = "false"
            attributes["aria-controls"] = "collapseOne"
          }
          +"Adjust Sigmoid"
        }
      }
      div("accordion-collapse collapse") {
        attrs["id"] = "collapseOne"
        attrs["aria-labelledby"] = "headingOne"
        attrs["data-bs-parent"] = "#accordionExample"
        div("accordion-body") {
          div("container") {


            div("row") {
              div("col") {}

              div("col") {
                +"Show Sigmoid: "
              }
              div("col") {
                // Checkbox input for showing sigmoid curve
                input(type = InputType.checkBox) {
                  attrs {
                    checked = showSigmoid
                    onChangeFunction = { event ->
                      val newShowSigmoid = (event.target as HTMLInputElement).checked
                      setShowSigmoid(newShowSigmoid)
                    }
                  }
                }
              }
              div("col") {}

            }

            div("row") {
              div("col") {}

              div("col") {
                +"Show line connecting Points: "
              }
              div("col") {
                input(type = InputType.checkBox) {
                  attrs {
                    checked = paintLine
                    onChangeFunction = { event ->
                      val newPaintLine = (event.target as HTMLInputElement).checked
                      setPaintLine(newPaintLine)
                    }
                  }
                }
              }
              div("col") {}

            }
          }
        }
      }
    }
  }

}

external interface LineChartProps : Props {
  var explodedCellsPercent: List<@pct Double>
  var names: List<String>
}
