package components.sop5

import components.table.CellTableRow
import components.table.ResultsTable
import it.neckar.bioexp.rest.detect.CellType
import it.neckar.react.common.*
import it.neckar.react.common.button.*
import kotlinx.browser.document
import kotlinx.css.*
import kotlinx.html.classes
import kotlinx.html.id
import org.w3c.dom.HTMLTextAreaElement
import react.*
import react.dom.*
import services.UiActions
import store.DetectedStateSOP5
import store.useSelector
import styled.*

/**
 * page that shows the results for SOP 2 after the detection is done
 */
val ResultsPageSOP5: FC<Props> = fc("ResultsPageSOP5") { props ->
  val imageState = useSelector { imageState }
  val deploymentZone = useSelector { deploymentZone }

  require(imageState is DetectedStateSOP5)

  div("container-fluid text-white") {

    if (deploymentZone.isCellcount()) {
      //feedback input
      div("mt-3 float-end") {
        h2 {
          +"Feedback"

          actionButton(
            classes = "btn btn-primary mx-3",
            icon = ButtonIcon.centered(FontAwesomeIcons.paperPlane),
            action = suspend {
              UiActions.submitFeedback((document.getElementById("feedbackInput") as HTMLTextAreaElement).value, imageState.filepath)
              (document.getElementById("feedbackInput") as HTMLTextAreaElement).value = ""
            }
          ) { +" Submit" }
        }

        styledTextarea() {
          css {
            height = LinearDimension("10rem")
            width = LinearDimension("100%")
          }
          attrs {
            id = "feedbackInput"
            placeholder = "Enter Feedback..."
          }
        }
      }
    }

    div("container pt-5 py-3") {

      div("row") {

        div("text-center fs-1 d-flex justify-content-between align-items-center") {
          actionButton(
            classes = "btn btn-primary",
            icon = ButtonIcon.centered(FontAwesomeIcons.arrowLeft),
            action = suspend { UiActions.setSOP5DefaultState() }
          ) { }

          div("fw-bold my-0") {
            +"Detection results for ${imageState.filename}"
          }
          //empty div for center text and left aligned back-button
          div { }
        }

        div("mt-5") {
          val rows = mutableListOf<CellTableRow>()

          rows.apply {
            add(CellTableRow("Rouleauxformation", imageState.detectionResult.detectedObjects.size))
          }

          ResultsTable {
            attrs {
              tableRows = rows
            }
          }
        }

        styledDiv {
          css {
            resize = Resize.both
            overflow = Overflow.auto
          }
          attrs.classes += "d-flex"
          img(src = imageState.imageData, classes = "flex-fill mw-100") {
            attrs {
              width = "100%"
              height = "100%"
            }
          }
        }
      }
    }
  }
}
