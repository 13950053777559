package components.login

import kotlinx.css.*
import react.*
import react.dom.*
import styled.*

/**
 * The login page - shows only the login form
 */
val LoginPage: FC<Props> = fc("LoginPage") {

  div("text-center") {
    styledDiv {
      css {
        width = 100.pct
        maxWidth = 330.px
        padding = Padding(15.px)
        margin = Margin(LinearDimension.auto, LinearDimension.auto)
      }


      div("py-5 text-center") {
        div("container text-white") {
          LoginForm {}
        }
      }
    }
  }

}
