package components.sop3

import components.table.ImageCellTableRow
import components.table.ResultsTableImagePreview
import it.neckar.bioexp.rest.detect.CellType
import it.neckar.open.formatting.percentageFormat0digits
import it.neckar.open.kotlin.lang.or0ifNaN
import it.neckar.open.unit.other.pct
import it.neckar.react.common.*
import it.neckar.react.common.button.*
import kotlinx.css.*
import react.*
import react.dom.*
import services.UiActions
import store.DetectedStateSOP3
import store.useSelector
import styled.*

/**
 * page that shows the results for SOP 3 after the detection is done
 */
val ResultsPageSOP3: FC<Props> = fc("ResultsPageSOP3") { props ->
  val imageState = useSelector { imageState }
  require(imageState is DetectedStateSOP3)

  val (fileNames, setFileNames) = useState(imageState.filenames)

  val detectionResults = imageState.detectionResults

  //Calculate the number of exploded cells in percent
  val explodedCellsPercentages: List<@pct Double> = useMemo(detectionResults) {
    //first cells, corresponds to 100% of cells, 0% of exploded cells
    //use max detections as base
    val baseResult = detectionResults.maxByOrNull { it.detectedObjects.size } ?: return@useMemo emptyList()
    val baseRBC = baseResult.detectedObjects.count { it.cellType == CellType.RBC }
    val baseRBCg = baseResult.detectedObjects.count { it.cellType == CellType.RBCg }

    detectionResults.map { detectionResult ->
      //if less cells than baseResult or more RBCg than baseResult, increase percentage
      val detectedRbcCount = detectionResult.detectedObjects.count { it.cellType == CellType.RBC }
      val detectedRBCgCount = detectionResult.detectedObjects.count { it.cellType == CellType.RBCg }

      //a cell counts as exploded if it is either not detected anymore or if it is detected as more RBCg than base
      val lessRBcCount = baseRBC - detectedRbcCount
      val moreRBCgCount = detectedRBCgCount - baseRBCg

      val numberOfMoreRBCandLessRBCG = lessRBcCount + moreRBCgCount
      val result = (numberOfMoreRBCandLessRBCG.toDouble() / (baseRBC + baseRBCg)).or0ifNaN()
      return@map if (result < 0) {
        0.0
      } else result
    }
  }

  val onImageNameChanged: (List<ImageCellTableRow>) -> Unit = useCallback(fileNames) { tableRows ->
    setFileNames(tableRows.map { it.imageName })
  }

  val rows = useMemo(detectionResults, fileNames) {
    detectionResults.map { detectionResult ->
      val detectedRbcCount = detectionResult.detectedObjects.count { it.cellType == CellType.RBC }
      val detectedRBCgCount = detectionResult.detectedObjects.count { it.cellType == CellType.RBCg }

      val index = detectionResults.indexOf(detectionResult)

      return@map ImageCellTableRow(
        imageIndex = index,
        imageName = fileNames[index],
        rbcCount = detectedRbcCount,
        rbcgCount = detectedRBCgCount,
        percentBurst = percentageFormat0digits.format(explodedCellsPercentages[index]),
        imageData = imageState.imageData?.get(index).orEmpty()
      )
    }
  }


  div("container-fluid text-white") {
    div("container pt-5 py-3") {

      div("column") {

        div("text-center fs-1 d-flex justify-content-between align-items-center") {
          actionButton(
            classes = "btn btn-primary",
            icon = ButtonIcon.centered(FontAwesomeIcons.arrowLeft),
            action = suspend { UiActions.setSOP3DefaultState() }
          ) { }

          div("fw-bold my-0") {
            +"Aquaporine osmotic resistance subpopulations"
          }

          //empty div for center text and left aligned back-button
          div { }
        }

        div("row mt-5") {

          div("col-sm-12") {
            SOP3ResultsChart {
              attrs {
                explodedCellsPercent = explodedCellsPercentages
                names = fileNames
              }
            }

            div("col-sm-12 mt-5") {
              ResultsTableImagePreview {
                attrs {
                  tableRows = rows
                  onImageNameChange = onImageNameChanged
                }
              }
            }
          }
        }
      }
    }
  }
}
