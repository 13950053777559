package it.neckar.bioexp.rest.detect

/**
 * The cell types that can be detected by the AI model
 */
enum class CellType(val description: String) {
  RBC("Red Blood Cell (Erythrocyte)"),
  RBCL("Elongated RBC"),
  RBCc("Cross-sectional View of RBC"),
  RBCe("Echinocyte"),
  RBCg("Red Blood Cell Ghost"),
  RBCr("Red Blood Cell Rouleaux Formation"),
  RBCs("Swollen Erythrocyte"),
}
