package it.neckar.bioexp.rest.io

import it.neckar.open.kotlin.serializers.defaultJsonConfiguration
import it.neckar.processStates.ProcessStateEntry
import kotlinx.serialization.json.JsonBuilder
import kotlinx.serialization.modules.SerializersModule
import kotlinx.serialization.modules.SerializersModuleBuilder
import kotlinx.serialization.modules.polymorphic

/**
 * Contains the kotlinx serialization configuration for the bioExp
 */
fun SerializersModuleBuilder.bioExpSerializationModel() {

  polymorphic(ProcessStateEntry::class) {
    //  subclass(AdvanceInvoiceProcessStateEntry::class)
    //  subclass(AssemblyBasementProcessStateEntry::class)
    //  subclass(AssemblyPortfolioProcessStateEntry::class)
    //  subclass(AssemblyRoofProcessStateEntry::class)
    //  subclass(BlueprintProcessStateEntry::class)
    //  subclass(ConfigurationProcessStateEntry::class)
    //  subclass(DocumentationProcessStateEntry::class)
    //  subclass(FinalAccountProcessStateEntry::class)
    //  subclass(FinishingUpProcessStateEntry::class)
    //  subclass(GridAssessmentProcessStateEntry::class)
    //  subclass(OrderSpecialMaterialProcessStateEntry::class)
    //  subclass(PrepareMaterialProcessStateEntry::class)
    //  subclass(PresentationProcessStateEntry::class)
    //  subclass(QuoteConfirmationProcessStateEntry::class)
    //  subclass(QuoteOfferProcessStateEntry::class)
    //  subclass(ScheduleAssemblyProcessStateEntry::class)
    //  subclass(StartupOperationsProcessStateEntry::class)
    //  subclass(SwitchMeterBoxProcessStateEntry::class)
    //}
  }
}

/**
 * contains the default properties and serializer module for lizergy
 * @see JsonBuilder
 * */
fun JsonBuilder.bioExpJsonConfiguration(prettyPrintEnabled: Boolean = true) {
  defaultJsonConfiguration(prettyPrintEnabled)
  serializersModule = SerializersModule {
    bioExpSerializationModel()
  }
}
