package it.neckar.react.common.upload

import it.neckar.commons.kotlin.js.safeGet
import it.neckar.react.common.*
import kotlinx.css.*
import kotlinx.html.InputType
import kotlinx.html.id
import kotlinx.html.js.onChangeFunction
import kotlinx.html.js.onDragEnterFunction
import kotlinx.html.js.onDragLeaveFunction
import kotlinx.html.js.onDragOverFunction
import kotlinx.html.js.onDropFunction
import org.w3c.dom.events.Event
import react.*
import react.dom.*
import react.dom.events.*
import styled.*
import web.file.FileList

/**
 * Upload field for files, opens popup when clicked or file is dropped into the field.
 * Allows multiple files to be selected at once.
 * calls uploadFunction when file is selected
 */
val MultiImageUploadField: FC<MultiImageUploadFieldProps> = fc("MultiImageUploadField") { props ->
  val (dragActive, setDragActive) = useState(false)

  val fileOnChange: (Event) -> Unit = useCallback { event ->
    val files = event.target.asDynamic().files as FileList
    props::uploadFunction.safeGet()(files)
  }

  val handleDrop: (Event) -> Unit = useCallback { event ->
    event.preventDefault()
    event.stopPropagation()
    setDragActive(false)
    val dataTransfer = (event.unsafeCast<DragEvent<*>>()).dataTransfer
    if (dataTransfer.files.length > 0) {
      val files = dataTransfer.files
      props::uploadFunction.safeGet()(files)
    }
  }

  val handleDrag: (Event) -> Unit = useCallback { event ->
    event.preventDefault()
    event.stopPropagation()
    if (event.type === "dragenter" || event.type === "dragover") {
      setDragActive(true)
    } else if (event.type === "dragleave") {
      setDragActive(false)
    }
  }

  div {
    styledForm {
      css {
        height = props::height.safeGet()
      }

      attrs.onDragEnterFunction = handleDrag
      attrs.onDragLeaveFunction = handleDrag
      attrs.onDragOverFunction = handleDrag
      attrs.onDropFunction = handleDrop

      input(InputType.file, classes = "d-none") {
        attrs.id = "input-file-upload"
        attrs.multiple = true
        attrs {
          onChangeFunction = fileOnChange
        }
        attrs.accept = props::acceptableFileTypes.safeGet().joinToString(",")
      }

      styledLabel {
        attrs.htmlForFixed = "input-file-upload"
        css {
          classes.addAll(listOf("bg-white", "border", "border-2", "h-100", "rounded-3", "d-flex", "justify-content-center", "align-items-center"))
          cursor = Cursor.pointer
        }
        if (dragActive) {
          css {
            classes.removeAt(0)
            classes.add("border-danger")
            classes.add("bg-secondary")
          }
        }

        p("text-dark") {
          +props::fieldText.safeGet()
        }
      }
    }
  }
}

external interface MultiImageUploadFieldProps : Props {
  var fieldText: String
  var uploadFunction: (FileList) -> Any
  var height: LinearDimension
  var acceptableFileTypes: List<String>
}
