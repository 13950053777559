package it.neckar.bioexp.rest.message

import it.neckar.rest.RestRequest
import kotlinx.serialization.Serializable

/**
 * Request sent to the Storage Service to upload feedback corresponding to a file
 */
@Serializable
data class FeedbackUploadRequest(
  /**
   * The name of the file to upload feedback for
   */
  val fileName: String,

  /**
   * The feedback text to upload
   */
  val feedbackText: String,
) : RestRequest {
}
