package it.neckar.bioexp.rest.model.temperature

import it.neckar.open.unit.quantity.Temperature
import kotlinx.serialization.Serializable
import kotlin.js.JsExport

@Serializable
@JsExport
data class MeasuredTemperature(
  val temperature: @Temperature Double,
)
