package it.neckar.bioexp.rest.detect

import it.neckar.geometry.Rectangle
import it.neckar.open.unit.other.Relative
import kotlinx.serialization.Serializable

/**
 * Represents a detected cell
 */
@Serializable
data class DetectedCell(
  /**
   * The cell type that has been detected
   */
  val cellType: CellType,

  /**
   * The (relative!) bounding box of the cell
   */
  val boundingBox: @Relative Rectangle,

  val confidence: DetectionConfidence,
)
