package components

import it.neckar.react.common.FontAwesome.faSpinner
import it.neckar.react.common.loading.*
import react.*
import react.dom.*
import store.DetectionRunningSOP1Ec
import store.DetectionRunningSOP3
import store.DetectionRunningSOP1Rbc
import store.DetectionRunningSOP2
import store.UploadingStateSOP3
import store.useSelector

/**
 * Page to show upload/detection progress
 */
val LoadingPage: FC<Props> = fc("LoadingPage") { props ->
  val imageState = useSelector { imageState }

  when (imageState) {
    is DetectionRunningSOP2-> {
      div("container-fluid text-white") {
        div("container py-3") {

          div("column mt-5") {

            div("row fs-5") {
              div("col-6 text-end") { +"Uploaded" }
              div("col-6 text-start") { +"✅" }
            }

            div("row fs-5") {
              div("col-6 text-end") { +"Running detection" }
              div("col-6 text-start") { faSpinner() }
            }
          }
        }
      }
    }

    is DetectionRunningSOP1Ec -> {
      div("container-fluid text-white") {
        div("container py-3") {

          div("column mt-5") {

            div("row fs-5") {
              div("col-6 text-end") { +"Uploaded" }
              div("col-6 text-start") { +"✅" }
            }

            div("row fs-5") {
              div("col-6 text-end") { +"Running EC Detection" }
              div("col-6 text-start") { faSpinner() }
            }

            div("row fs-5") {
              div("col-6 text-end") { +"RBC Detection" }
              div("col-6 text-start") { +"⏸\uFE0F" }
            }
          }
        }
      }
    }

    is DetectionRunningSOP1Rbc -> {
      div("container-fluid text-white") {
        div("container py-3") {

          div("column mt-5") {

            div("row fs-5") {
              div("col-6 text-end") { +"Uploaded" }
              div("col-6 text-start") { +"✅" }
            }

            div("row fs-5") {
              div("col-6 text-end") { +"EC Detection" }
              div("col-6 text-start") { +"✅" }
            }

            div("row fs-5") {
              div("col-6 text-end") { +"Running RBC detection" }
              div("col-6 text-start") { faSpinner() }
            }
          }
        }
      }
    }

    is UploadingStateSOP3 -> {
      div("container-fluid text-white") {
        div("container py-3") {

          div("column mt-5") {

            div("row fs-5") {
              div("col-6 text-end") { +"Uploading ${imageState.currentlyUploadingFileName}" }
              div("col-6 text-start") { faSpinner() }
              div("mt-3") {
                bootstrapProgressBar {
                  attrs {
                    title = ""
                    progressType = ProgressType.Info
                    currentPercentage = imageState.alreadyUploadedFiles.size.toDouble() / imageState.numberOfFilesToUpload.toDouble()
                    style = ProgressBarStyle.Default
                  }
                }
              }
            }

            div("row fs-5 mt-3") {
              div("col-6 text-end") { +"Detection" }
              div("col-6 text-start") { +"⏸\uFE0F" }
            }
          }
        }
      }
    }

    is DetectionRunningSOP3 -> {
      div("container-fluid text-white") {
        div("container py-3") {

          div("column mt-5") {

            div("row fs-5") {
              div("col-6 text-end") { +"Uploaded" }
              div("col-6 text-start") { +"✅" }
            }

            div("row fs-5 mt-3") {
              div("col-6 text-end") { +"Detecting ${imageState.currentlyDetectingFileName}" }
              div("col-6 text-start") { faSpinner() }
            }
            div("mt-3") {
              bootstrapProgressBar {
                attrs {
                  title = ""
                  progressType = ProgressType.Info
                  currentPercentage = imageState.alreadyDetectedFiles.size.toDouble() / imageState.numberOfFilesToDetect.toDouble()
                  style = ProgressBarStyle.Default
                }
              }
            }
          }
        }
      }
    }

    else -> {
      div("container-fluid text-white") {
        div("container py-3") {

          div("column mt-5") {

            div("row fs-5") {
              div("col-6 text-end") { +"Uploading" }
              div("col-6 text-start") { faSpinner() }
            }

            div("row fs-5") {
              div("col-6 text-end") { +"Detection" }
              div("col-6 text-start") { +"⏸\uFE0F" }
            }
          }
        }
      }
    }
  }
}
