package store.actions

import it.neckar.common.redux.ChangeType
import store.BioExpUiState
import store.ImageState
import store.StateAction
import store.ThresholdState

/**
 * sets the image detection threshold state
 */
class ThresholdAction(val newState: ThresholdState) : StateAction {
  override val type: ChangeType = ChangeType.HistoryReset

  override fun BioExpUiState.updateState(): BioExpUiState {
    return this.copy(thresholdState = newState)
  }
}
