package store

import it.neckar.bioexp.rest.client.DeploymentZone
import it.neckar.common.redux.Dispatch
import it.neckar.common.redux.StateAction
import it.neckar.logging.Logger
import it.neckar.logging.LoggerFactory
import it.neckar.logging.debug
import it.neckar.react.common.annotations.*
import it.neckar.react.common.redux.*
import store.AppStateLocalStorage.connectWithLocalStorage

/**
 * Contains the configuration for the store
 */
object StoreSupport {
  /**
   * Initializes the store.
   */
  fun initializeStore(deploymentZone: DeploymentZone.Client) {

    store = UndoStore.create(BioExpUiState(deploymentZone=deploymentZone)).also { store ->
      //Load the values from local storage - automatically saves data to local storage
      store.connectWithLocalStorage()

      store.subscribe {
        logger.debug { "Store updated. Update counter: ${it.state.updateCounter}" }
      }
    }

    //Register the global dispatch action
    Dispatch.registerDispatchAction { stateAction ->
      store.dispatch(stateAction)
    }
  }

  private val logger: Logger = LoggerFactory.getLogger("store")
}

/**
 * The type for the store of the planner
 */
typealias BioExpStore = UndoStore<BioExpUiState>
typealias StateAction = StateAction<BioExpUiState>

lateinit var store: BioExpStore


/**
 * Custom selector that has the app state type hard coded
 */
@UsesHooks
inline fun <R> useSelector(crossinline selector: BioExpUiState.() -> R): R {
  return useUndoStoreSelector(selector)
}
