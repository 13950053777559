package it.neckar.bioexp.rest.message

import it.neckar.rest.RestResponse
import it.neckar.rest.pagination.PaginationInfo
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * Represents the response for a camera image capture call
 */
@Serializable
sealed interface CameraImageResponse : RestResponse<List<CameraCaptureResult>> {
  override val isSuccess: Boolean
    get() = this is Success

  override fun asSuccess(): Success {
    return this as Success
  }

  @Serializable
  @SerialName("success")
  data class Success(
    override val data: List<CameraCaptureResult>,
    override val paginationInfo: PaginationInfo,
  ) : CameraImageResponse, RestResponse.PaginatedSuccessResponse<List<CameraCaptureResult>>

  @Serializable
  @SerialName("failure")
  data object Failure : CameraImageResponse, RestResponse.FailureResponse<List<CameraCaptureResult>>

  companion object {
    /**
     * Helper method to create a success object - with the correct type
     */
    fun success(data: List<CameraCaptureResult>, paginationInfo: PaginationInfo): CameraImageResponse = Success(data, paginationInfo)

    /**
     * Helper method to create a failure object - with the correct type
     */
    fun failure(): CameraImageResponse = Failure
  }
}
