package it.neckar.bioexp.rest.message

import it.neckar.bioexp.rest.detect.RBCDetectionResult
import it.neckar.rest.RestResponse
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

/**
 * Represents the response for a detection call for RBC
 */
@Serializable
sealed interface DetectResponseRBC : RestResponse<RBCDetectionResult> {
  override val isSuccess: Boolean
    get() = this is Success

  override fun asSuccess(): RestResponse.SuccessResponse<RBCDetectionResult> {
    return this as Success
  }

  @Serializable
  @SerialName("success")
  data class Success internal constructor(override val data: RBCDetectionResult) : DetectResponseRBC, RestResponse.SuccessResponse<RBCDetectionResult> {
    val detections: RBCDetectionResult
      get() = data
  }

  @Serializable
  @SerialName("failure")
  data object Failure : DetectResponseRBC, RestResponse.FailureResponse<RBCDetectionResult>

  companion object {
    /**
     * Helper method to create a success object - with the correct type
     */
    fun success(data: RBCDetectionResult): DetectResponseRBC = Success(data)

    /**
     * Helper method to create a failure object - with the correct type
     */
    fun failure(): DetectResponseRBC = Failure

    /**
     * Creates a success method for non-null results, failure for null results
     */
    fun createFor(data: RBCDetectionResult?): DetectResponseRBC {
      return if (data != null) {
        success(data)
      } else {
        failure()
      }
    }
  }

}
