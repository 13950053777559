package components.sop2

import components.table.CellTableRow
import components.table.ResultsTable
import it.neckar.bioexp.rest.detect.CellType
import it.neckar.react.common.*
import it.neckar.react.common.button.*
import kotlinx.browser.document
import kotlinx.css.*
import kotlinx.html.classes
import kotlinx.html.id
import org.w3c.dom.HTMLTextAreaElement
import react.*
import react.dom.*
import services.UiActions
import store.DetectedStateSOP2
import store.useSelector
import styled.*

/**
 * page that shows the results for SOP 2 after the detection is done
 */
val ResultsPageSOP2: FC<Props> = fc("ResultsPageSOP2") { props ->
  val imageState = useSelector { imageState }
  val deploymentZone = useSelector { deploymentZone }

  require(imageState is DetectedStateSOP2)

  div("container-fluid text-white") {

    if (deploymentZone.isCellcount()) {
      //feedback input
      div("mt-3 float-end") {
        h2 {
          +"Feedback"

          actionButton(
            classes = "btn btn-primary mx-3",
            icon = ButtonIcon.centered(FontAwesomeIcons.paperPlane),
            action = suspend {
              UiActions.submitFeedback((document.getElementById("feedbackInput") as HTMLTextAreaElement).value, imageState.filepath)
              (document.getElementById("feedbackInput") as HTMLTextAreaElement).value = ""
            }
          ) { +" Submit" }
        }

        styledTextarea() {
          css {
            height = LinearDimension("10rem")
            width = LinearDimension("100%")
          }
          attrs {
            id = "feedbackInput"
            placeholder = "Enter Feedback..."
          }
        }
      }
    }

    div("container pt-5 py-3") {

      div("row") {

        div("text-center fs-1 d-flex justify-content-between align-items-center") {
          actionButton(
            classes = "btn btn-primary",
            icon = ButtonIcon.centered(FontAwesomeIcons.arrowLeft),
            action = suspend { UiActions.setSOP2DefaultState() }
          ) { }

          div("fw-bold my-0") {
            +"Detection results for ${imageState.filename}"
          }
          //empty div for center text and left aligned back-button
          div { }
        }

        div("mt-5 bg-white") {
          SOP2ResultsChart {
            attrs {
              cellTypes = CellType.entries
              detectedCells = imageState.RBCDetectionResult.detectedObjects
            }
          }
        }
      }


      div("mt-5") {
        var totalDetections = 0
        var combinedRbcRbccRbclCount = 0

        val rows = CellType.entries.map { cellType ->
          val detectedCellsForClassCount = imageState.RBCDetectionResult.detectedObjects.count { it.cellType == cellType }
          totalDetections += detectedCellsForClassCount

          if (cellType == CellType.RBC || cellType == CellType.RBCc || cellType == CellType.RBCL) {
            combinedRbcRbccRbclCount += detectedCellsForClassCount
          }

          CellTableRow("${cellType.name} - ${cellType.description}", detectedCellsForClassCount)
        }.toMutableList()

        rows.apply {
          add(CellTableRow("RBC + RBCc + RBCL", combinedRbcRbccRbclCount))
          add(CellTableRow("Total", totalDetections))
        }

        ResultsTable {
          attrs {
            tableRows = rows
          }
        }

      }

      /**
       * Legend
       */
      div("container") {
        div("row") {

          div("col-sm-1 text-center bg-rbc") {
            +"RBC"
          }

          div("col-sm-1 text-center bg-rbcl text-black") {
            +"RBCL"
          }

          div("col-sm-1 text-center bg-rbcc") {
            +"RBCc"
          }

          div("col-sm-1 text-center bg-rbce text-black") {
            +"RBCe"
          }

          div("col-sm-1 text-center bg-rbcg text-black") {
            +"RBCg"
          }

          div("col-sm-1 text-center bg-rbcr text-black") {
            +"RBCr"
          }

          div("col-sm-1 text-center bg-rbcs") {
            +"RBCs"
          }
        }
      }

      styledDiv {
        css {
          resize = Resize.both
          overflow = Overflow.auto
        }
        attrs.classes += "border border-1 border-dark d-flex"
        img(src = imageState.imageData, classes = "flex-fill mw-100") {
          attrs {
            width = "100%"
            height = "100%"
          }
        }
      }
    }
  }
}
